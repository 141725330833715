import React from 'react';
import MinimalLayout from '../components/layouts/MinimalLayout';
import OrderSuccess from '../components/OrderSuccess';

const Success: React.FC = () => {
    return (
        <MinimalLayout>
            <OrderSuccess />
        </MinimalLayout>
    );
};

export default Success;
