import Animator from './Animator';
import styled from 'styled-components';
import GlobalLoader from './GlobalLoader';
import { getUrlParams } from '../util/url';
import { CartContext } from './context/CartContext';
import React, { useContext, useEffect, useState } from 'react';
import { modularScale } from '../util/modularScale';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline } from '@mdi/js';
import { Link } from 'gatsby';
import axios, { handleError } from '../util/axios';
import { navigate } from 'gatsby';
import ProductDownload from './ProductDownload';
import { Button } from '@material-ui/core';
import { gradient } from '../util/theme';

const Container = styled.div`
	margin-top: 4rem;
`;

const LoadingContainer = styled.div`
    @media (min-width: 900px) {
        margin-top: 8rem;
    }
`;

const Title = styled.h1`
	font-size: ${modularScale(1)};
	font-weight: 400;
	text-align: center;
	
	@media (min-width: 900px) {
        font-size: ${modularScale(2)};
        margin-bottom: 1rem;
    }
`;

const StatusContainer = styled.div`
	background: #fff;
	padding: 1rem;
	margin: 0 auto;
	width: 100%;
	max-width: 600px;
	border-radius: 4px;
	
	@media (min-width: 900px) {
        padding: 2rem;
        margin-bottom: 1rem;
    }
`;

const ProductList = styled.div`
	padding: 1rem;
	margin: 0 auto;
	width: 100%;
	max-width: 600px;
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
    justify-content: center;
    margin-top: 2rem;
`;

const StyledButton = styled(Button)`
	color: #fff;
    background: ${gradient};

	&:hover, &:focus, &:active {
	    color: #fff;
        background: ${gradient};
	}
`;

const OrderSuccess: React.FC = () => {
    const { clearCart } = useContext(CartContext);
    const [status, setStatus] = useState('verifying');
    const [showResult, setShowResult] = useState(false);
    const [products, setProducts] = useState<any[]>([]);

    useEffect(() => {
        const urlParams: any = getUrlParams(document.location.search);
        const sessionId = urlParams.session_id;
        const token = urlParams.token;

        if (!sessionId && !token) {
            navigate('/');
            return;
        }

        if (sessionId && token) {
            navigate('/');
            return;
        }

        const confirmStripeOrder = async (id: string): Promise<void> => {
            let products = [];

            try {
                const response = await axios.post('/stripe/order/complete', { orderId: id });
                products = response.data.products;
            } catch (err) {
                setStatus('payment_unverified');
                handleError(err);
                return;
            }

            setProducts(products);
            setStatus('payment_verified');
            clearCart();
        }

        const confirmPaypalOrder = async (id: string): Promise<void> => {
            let products = [];

            try {
                const response = await axios.post('/paypal/order/complete', { orderId: id });
                products = response.data.products;
            } catch (err) {
                setStatus('payment_unverified');
                handleError(err);
                return;
            }

            setProducts(products);
            setStatus('payment_verified');
            clearCart();
        }

        if (token) {
            confirmPaypalOrder(token);
            return;
        }

        if (sessionId) {
            confirmStripeOrder(sessionId);
            return;
        }
    }, []);

    return (
        <Container>
            <Animator
                onExited={() => setShowResult(true)}
                isVisible={status === 'verifying'}
            >
                <LoadingContainer>
                    <GlobalLoader text={'Verifying payment...'} />
                </LoadingContainer>
            </Animator>

            <Animator isVisible={showResult && status === 'payment_verified'}>
                <StatusContainer>
                    <Title>Thank you!</Title>
                    <p>
                        Your payment has been received. Below, you will find your sheet music download links.
                        You may also visit the <Link to={'/my-products'}>My Sheets</Link> page at any time to
                        re-download any of your purchased sheet music. Watch your inbox for a confirmation of
                        your order and an additional link to download your digital goods!
                    </p>
                </StatusContainer>
                <ProductList>
                    { products.map((product, i) => <ProductDownload key={i} order={i+1} product={product} />)}
                </ProductList>
                <ButtonContainer>
                    <StyledButton component={Link} to={'/sheets'} variant="contained" color="primary">
                        Continue Shopping
                    </StyledButton>
                </ButtonContainer>
            </Animator>

            <Animator isVisible={showResult && status === 'payment_unverified'}>
                <StatusContainer>
                    <Title>Uh oh...</Title>
                    <p>
                        Your payment could not be verified. Please refresh this page to try again. If the problem
                        persists, additional steps are required to ensure that your payment has gone through and
                        has been received by our payment processor. Please contact support
                        at <a href='mailto:support@pauliano.com'>support@pauliano.com</a> to resolve this issue.
                    </p>
                </StatusContainer>
            </Animator>
        </Container>
    )
};

export default OrderSuccess;
